/*
 * @Author: fengjiahua fenjiahua@qq.com
 * @Date: 2024-05-22 09:11:33
 * @LastEditors: fengjiahua fenjiahua@qq.com
 * @LastEditTime: 2024-11-07 15:49:33
 * @FilePath: \qcwp-vue\packages\common\src\const\index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export * from './enum'
export * from './mediaQuery'
export * from './version'

const isDev = process.env.NODE_ENV === 'development'
// www.qcwp.com
export const COOKIE_BASE_DOMAIN = isDev ? 'localhost' : '.qcwp.com'
export const BASE_DOMAIN = isDev ? 'localhost' : 'qcwp.com'
export const BASE_HOST = isDev ? `http://${BASE_DOMAIN}` : (typeof window !== 'undefined' ? window.location.origin : `https://www.${BASE_DOMAIN}`)

//  www.cheqiao.com
export const COOKIE_BASE_EV_DOMAIN = isDev ? 'localhost' : '.qcwp.com'
export const BASE_EV_DOMAIN = isDev ? 'localhost' : 'ev.qcwp.com'
export const BASE_EV_HOST = isDev ? `http://${BASE_EV_DOMAIN}` : `https://www.${BASE_DOMAIN}`

export const BASE_HOST_WEB = isDev ? `${BASE_HOST}:3123` : BASE_HOST
export const BASE_HOST_EV = isDev ? `${BASE_HOST}:3223` : BASE_EV_HOST
export const BASE_HOST_USER = isDev ? `${BASE_HOST}:5555` : `${BASE_HOST}/web-admin`

// export const API_BASE_HOST_USER = 'https://1.315.gx.cn'
export const FILE_PRE_HOST_REGEXP = /http(s)?:\/\/qcts\.(gz|cdn)\.bcebos\.com/ // 文件前缀
export const FILE_PRE_HOST = 'https://qcts.cdn.bcebos.com' // 文件前缀

export const FILE_PRE_HOST_GZ = 'https://qcts.gz.bcebos.com' // 文件前缀

// export const IMG_SIZE_REG = '?x-bce-process=image/resize,m_lfit,w_{width},h_{height}'
export const IMG_SIZE_REG = '?x-bce-process=image/resize,m_fill,w_{width},h_{height}'
export const IMG_WATER_MARK_REG = '/watermark,color_e9e9e9,type_RlpMYW5UaW5nSGVpU0RCMUdC,size_16,g_9,x_20,y_20,text_{watermark}'

export const UPLOAD_IMAGE_TYPE = 'image/png,image/jpeg,image/webp'

// PRO_CHANGE
export const WX_OPEN_APPID = 'wx4278ec0d6702ac41' // 网评
export const WX_MP_APPID = 'wx725af8e5be2ee981' // 网评
// export const WX_OPEN_APPID = 'wx374d56003d74e9d0' // 投诉网
// export const WX_MP_APPID = 'wx725af8e5be2ee981' // 投诉网
