/**
 * 给图片添加域名
 */
import { createRegExp, isString } from '@qcwp/utils'
import { FILE_PRE_HOST, FILE_PRE_HOST_REGEXP, IMG_SIZE_REG, IMG_WATER_MARK_REG } from '../const'

export const addHost = (src?: string, host: string = FILE_PRE_HOST): string => {
  if (src && typeof src == 'string' && !src.match(/http/) && !src.match(/(ws|rtmp)/) && !src.match(/^data:.*;base64.*/)) {
    src = host + src
    return src
  }
  return src || ''
}

/**
 * 移除文件资源路径前缀域名
 * @param src string
 * @returns string
 */
export function removeHost(str: string, host = FILE_PRE_HOST) {
  if (!str || str.match(/^data:.*;base64.*/) || !isString(str))
    return str
  if (host !== FILE_PRE_HOST)
    return str.replace(new RegExp(host, 'gi'), '')

  let newStr = str.replace(FILE_PRE_HOST_REGEXP, '')
  newStr = newStr.split('?x-bce-process=image')[0]
  return newStr
}

export const imageWater = (src = '', waterMark?: string | null) => {
  if (src.includes('/watermark') || !waterMark)
    return src

  return src + IMG_WATER_MARK_REG.replace('{watermark}', waterMark)
}
export const imageFormat = (src = '', format: 'png' | 'jpeg' | 'webp' = 'webp') => {
  if (!src || /\/format,f_(png|jpeg|webp)/.test(src))
    return src
  return `${src}/format,f_${format}`
}
export const imageSize = (src = '', options: {
  width: number
  height: number
  type?: 'lfit' | 'fill'
}) => {
  if (!src || /\/resize/.test(src))
    return src

  const { width, height, type = 'fill' } = options
  return `${src}/resize,m_${type},w_${width},h_${height}`
}
//  https://qcts-qcwp.gz.bcebos.com/qcts-qcwp/qcts/complain/img/202204/2ede607e8e3f416682d81a1fb0c46664.png?x-bce-process=image/resize,m_lfit,w_1100,h_500
export const imageTransform = (src = '', option: {
  width?: number
  height?: number
  type?: 'lfit' | 'fill'
  format?: 'png' | 'jpeg' | 'webp'
  waterMark?: string | null
  host?: string
}) => {
  const { width, height, type, format, waterMark, host = FILE_PRE_HOST } = option
  if (
    !src
    || !isString(src)
    || src.match(/^data:.*;base64.*/)
    || src.match(/(ws|rtmp)/)
    || (src.match(/^http/) && !src.match(/(qcts|qcwp|qcts-qcwp).(gz|cdn).bcebos.com/))
  )
    return src

  let _src = src

  if (src.match(/^\//))
    _src = addHost(src, host)

  const prefix = '?x-bce-process=image'
  if (!src.includes(prefix))
    _src += prefix

  if (format)
    _src = imageFormat(_src, format)

  if (width && height)
    _src = imageSize(_src, { width, height, type })

  if (waterMark)
    _src = imageWater(_src, waterMark)

  return _src
}
/**
 * @deprecated use imageTransform
 */
export const imgSize = (src: string, sizeWidth: number, sizeHeight: number, waterMark?: string | null) => {
  if (!src)
    return src
  // base64时直接返回
  if (src?.match(/^data:.*;base64.*/))
    return src

  // let size = '' // _width x height
  let sizeQuery = IMG_SIZE_REG // ?x-bce-process=image/resize,m_lfit,w_{width},h_{height}
  // 水印
  let waterMakerQuery = ''
  if (waterMark)
    waterMakerQuery = IMG_WATER_MARK_REG.replace('{watermark}', waterMark)

  if (sizeWidth || sizeHeight) {
    sizeWidth = sizeWidth || sizeHeight
    sizeHeight = sizeHeight || sizeWidth
    // size = `_${sizeWidth}x${sizeHeight}`
    sizeQuery = IMG_SIZE_REG.replace('{width}', sizeWidth.toString()).replace('{height}', sizeHeight.toString())
  }
  if (src && typeof src == 'string' && !src.match(/http/)) {
    src = addHost(src) ?? ''
    return src + sizeQuery + waterMakerQuery
  }
  // else if (src && typeof src == 'string' && src.match(window.location.host) && !src.match(/\?x-bce-process=image\/resize/)) {
  //   return src + sizeQuery
  // }
  else if (src && typeof src == 'string' && src.match(/(qcts|qcwp|qcts-qcwp).(gz|cdn).bcebos.com/) && !src.match(/\?x-bce-process=image\/resize/)) {
    return src + sizeQuery + waterMakerQuery
  }
  return src || ''
}

/**
 * 给富文本字符串中的图片标签src加上域名地址
 * @param str string
 * @param host string  default:FILE_PRE_HOST
 * @returns string
 */
export function contentAddHost(str: string, host = FILE_PRE_HOST) {
  // str = str ? str : '<P><img src="http://bbs.cn.yimg.com/user_img/200701/31/jisuanji986_117025184198149.jpg"><img src="https://bbs.cn.yimg.com/user_img/200701/31/jisuanji986_117025184198149.jpg"><img src="/200701/31/jisuanji986_117025184198149.jpg"><img src="/jisuanji986_117025184198149.jpg"></P>';
  // a 为富文本的字符串内容，为了测试，只写了img标签
  const b = /<(?:img|video|audio) [^>]*src=['"]([^'"]+)[^>]*>/g// img 标签取src里面内容的正则
  const s = str.match(b)// 取到所有img标签 放到数组 s里面
  if (!s)
    return str

  const srcSource = new Set<string>()
  for (let i = 0; i < s.length; i++) {
    const srcImg = s[i].replace(b, '$1')// 取src面的内容
    // 若src前4位置或者前5位是http、https则不做任何修改,或者是base64
    // console.log('不做任何修改');
    if (srcImg.match(/^http/) || srcImg.match(/^data:.*;base64.*/))
      continue
    else
      srcSource.add(srcImg)
  }
  srcSource.forEach((src) => {
    // 修改富文本字符串内容 img标签src 相对路径改为绝对路径
    const newSrc = (host && host !== FILE_PRE_HOST) ? host + src : imageTransform(src, { format: 'jpg' })
    str = str.replace(createRegExp(src, 'g'), newSrc)
  })
  return str
}
/**
 * 移除字符串中的资源拼接地址
 * @param str string
 * @param host string  default:FILE_PRE_HOST
 * @returns string
 */
export function contentRemoveHost(str: string, host = FILE_PRE_HOST) {
  if (!str)
    return str

  const images = getContentImages(str)
  if (!images.length)
    return str

  for (const item of images) {
    const newSrc = removeHost(item, host)
    str = str.replace(createRegExp(item, 'g'), newSrc)
  }
  return str
}

export function lazyImages(str: string) {
  const b = /<img/g
  str = str.replace(b, '<img loading="lazy"')

  return str
}

/**
 * 获取富文本字符串中的图片
 * @param str string
 * @param host string  default:FILE_PRE_HOST
 * @returns string
 */
export function getContentImages(str: string) {
  if (!str)
    return []

  // str = str ? str : '<P><img src="http://bbs.cn.yimg.com/user_img/200701/31/jisuanji986_117025184198149.jpg"><img src="https://bbs.cn.yimg.com/user_img/200701/31/jisuanji986_117025184198149.jpg"><img src="/200701/31/jisuanji986_117025184198149.jpg"><img src="/jisuanji986_117025184198149.jpg"></P>';
  // a 为富文本的字符串内容，为了测试，只写了img标签
  const b = /<img [^>]*src=['"]([^'"]+)[^>]*>/g// img 标签取src里面内容的正则
  const s = str.match(b)// 取到所有img标签 放到数组 s里面
  if (!s)
    return []

  const images = []
  for (let i = 0; i < s.length; i++) {
    const srcImg = s[i].replace(b, '$1')// 取src面的内容
    if (srcImg && !srcImg.match(/^data:.*;base64.*/))
      images.push(srcImg)
  }
  return images
}

export function contentRemoveStyle(str: string): string {
  const removeRegExps = [
    [/font-size\:(.*?);/gi, ''],
    [/font-family\:(.*?);/gi, ''],
  ] as const
  for (const item of removeRegExps)
    str = str.replace(item[0], item[1])

  return str
}

export function formatPrice(min?: number | string | null, max?: number | string | null, defaultValue = '暂无报价') {
  if (min && max)
    return min === max ? `${min}万元` : `${min} - ${max}万元`
  else if (min && !max)
    return `${min}万元`
  else if (max && !min)
    return `${max}万元`
  else
    return defaultValue
}
