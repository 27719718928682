const provinceData = [
  {
    id: '110000',
    name: '北京',
    short_name: '北京',
    f_id: '1',
  },
  {
    id: '120000',
    name: '天津',
    short_name: '天津',
    f_id: '1',
  },
  {
    id: '130000',
    name: '河北省',
    short_name: '河北',
    f_id: '1',
  },
  {
    id: '140000',
    name: '山西省',
    short_name: '山西',
    f_id: '1',
  },
  {
    id: '150000',
    name: '内蒙古自治区',
    short_name: '内蒙古',
    f_id: '1',
  },
  {
    id: '210000',
    name: '辽宁省',
    short_name: '辽宁',
    f_id: '1',
  },
  {
    id: '220000',
    name: '吉林省',
    short_name: '吉林',
    f_id: '1',
  },
  {
    id: '230000',
    name: '黑龙江省',
    short_name: '黑龙江',
    f_id: '1',
  },
  {
    id: '310000',
    name: '上海',
    short_name: '上海',
    f_id: '1',
  },
  {
    id: '320000',
    name: '江苏省',
    short_name: '江苏',
    f_id: '1',
  },
  {
    id: '330000',
    name: '浙江省',
    short_name: '浙江',
    f_id: '1',
  },
  {
    id: '340000',
    name: '安徽省',
    short_name: '安徽',
    f_id: '1',
  },
  {
    id: '350000',
    name: '福建省',
    short_name: '福建',
    f_id: '1',
  },
  {
    id: '360000',
    name: '江西省',
    short_name: '江西',
    f_id: '1',
  },
  {
    id: '370000',
    name: '山东省',
    short_name: '山东',
    f_id: '1',
  },
  {
    id: '410000',
    name: '河南省',
    short_name: '河南',
    f_id: '1',
  },
  {
    id: '420000',
    name: '湖北省',
    short_name: '湖北',
    f_id: '1',
  },
  {
    id: '430000',
    name: '湖南省',
    short_name: '湖南',
    f_id: '1',
  },
  {
    id: '440000',
    name: '广东省',
    short_name: '广东',
    f_id: '1',
  },
  {
    id: '450000',
    name: '广西壮族自治区',
    short_name: '广西',
    f_id: '1',
  },
  {
    id: '460000',
    name: '海南省',
    short_name: '海南',
    f_id: '1',
  },
  {
    id: '500000',
    name: '重庆',
    short_name: '重庆',
    f_id: '1',
  },
  {
    id: '510000',
    name: '四川省',
    short_name: '四川',
    f_id: '1',
  },
  {
    id: '520000',
    name: '贵州省',
    short_name: '贵州',
    f_id: '1',
  },
  {
    id: '530000',
    name: '云南省',
    short_name: '云南',
    f_id: '1',
  },
  {
    id: '540000',
    name: '西藏自治区',
    short_name: '西藏',
    f_id: '1',
  },
  {
    id: '610000',
    name: '陕西省',
    short_name: '陕西',
    f_id: '1',
  },
  {
    id: '620000',
    name: '甘肃省',
    short_name: '甘肃',
    f_id: '1',
  },
  {
    id: '630000',
    name: '青海省',
    short_name: '青海',
    f_id: '1',
  },
  {
    id: '640000',
    name: '宁夏回族自治区',
    short_name: '宁夏',
    f_id: '1',
  },
  {
    id: '650000',
    name: '新疆维吾尔自治区',
    short_name: '新疆',
    f_id: '1',
  },
  {
    id: '710000',
    name: '台湾',
    short_name: '台湾',
    f_id: '1',
  },
  {
    id: '810000',
    name: '香港特别行政区',
    short_name: '香港',
    f_id: '1',
  },
  {
    id: '820000',
    name: '澳门特别行政区',
    short_name: '澳门',
    f_id: '1',
  },
  {
    id: '830000',
    name: '南海诸岛',
    short_name: '南海诸岛',
    f_id: '1',
  },
]
export default provinceData
