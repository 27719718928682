const cityData = [
  {
    id: '110100',
    name: '北京市',
    short_name: '北京',
    f_id: '110000',
  },
  {
    id: '120100',
    name: '天津市',
    short_name: '天津',
    f_id: '120000',
  },
  {
    id: '130100',
    name: '石家庄市',
    short_name: '石家庄',
    f_id: '130000',
  },
  {
    id: '130200',
    name: '唐山市',
    short_name: '唐山',
    f_id: '130000',
  },
  {
    id: '130300',
    name: '秦皇岛市',
    short_name: '秦皇岛',
    f_id: '130000',
  },
  {
    id: '130400',
    name: '邯郸市',
    short_name: '邯郸',
    f_id: '130000',
  },
  {
    id: '130500',
    name: '邢台市',
    short_name: '邢台',
    f_id: '130000',
  },
  {
    id: '130600',
    name: '保定市',
    short_name: '保定',
    f_id: '130000',
  },
  {
    id: '130700',
    name: '张家口市',
    short_name: '张家口',
    f_id: '130000',
  },
  {
    id: '130800',
    name: '承德市',
    short_name: '承德',
    f_id: '130000',
  },
  {
    id: '130900',
    name: '沧州市',
    short_name: '沧州',
    f_id: '130000',
  },
  {
    id: '131000',
    name: '廊坊市',
    short_name: '廊坊',
    f_id: '130000',
  },
  {
    id: '131100',
    name: '衡水市',
    short_name: '衡水',
    f_id: '130000',
  },
  {
    id: '140100',
    name: '太原市',
    short_name: '太原',
    f_id: '140000',
  },
  {
    id: '140200',
    name: '大同市',
    short_name: '大同',
    f_id: '140000',
  },
  {
    id: '140300',
    name: '阳泉市',
    short_name: '阳泉',
    f_id: '140000',
  },
  {
    id: '140400',
    name: '长治市',
    short_name: '长治',
    f_id: '140000',
  },
  {
    id: '140500',
    name: '晋城市',
    short_name: '晋城',
    f_id: '140000',
  },
  {
    id: '140600',
    name: '朔州市',
    short_name: '朔州',
    f_id: '140000',
  },
  {
    id: '140700',
    name: '晋中市',
    short_name: '晋中',
    f_id: '140000',
  },
  {
    id: '140800',
    name: '运城市',
    short_name: '运城',
    f_id: '140000',
  },
  {
    id: '140900',
    name: '忻州市',
    short_name: '忻州',
    f_id: '140000',
  },
  {
    id: '141000',
    name: '临汾市',
    short_name: '临汾',
    f_id: '140000',
  },
  {
    id: '141100',
    name: '吕梁市',
    short_name: '吕梁',
    f_id: '140000',
  },
  {
    id: '150100',
    name: '呼和浩特市',
    short_name: '呼和浩特',
    f_id: '150000',
  },
  {
    id: '150200',
    name: '包头市',
    short_name: '包头',
    f_id: '150000',
  },
  {
    id: '150300',
    name: '乌海市',
    short_name: '乌海',
    f_id: '150000',
  },
  {
    id: '150400',
    name: '赤峰市',
    short_name: '赤峰',
    f_id: '150000',
  },
  {
    id: '150500',
    name: '通辽市',
    short_name: '通辽',
    f_id: '150000',
  },
  {
    id: '150600',
    name: '鄂尔多斯市',
    short_name: '鄂尔多斯',
    f_id: '150000',
  },
  {
    id: '150700',
    name: '呼伦贝尔市',
    short_name: '呼伦贝尔',
    f_id: '150000',
  },
  {
    id: '150800',
    name: '巴彦淖尔市',
    short_name: '巴彦淖尔',
    f_id: '150000',
  },
  {
    id: '150900',
    name: '乌兰察布市',
    short_name: '乌兰察布',
    f_id: '150000',
  },
  {
    id: '152200',
    name: '兴安盟',
    short_name: '兴安',
    f_id: '150000',
  },
  {
    id: '152500',
    name: '锡林郭勒盟',
    short_name: '锡林郭勒',
    f_id: '150000',
  },
  {
    id: '152900',
    name: '阿拉善盟',
    short_name: '阿拉善',
    f_id: '150000',
  },
  {
    id: '210100',
    name: '沈阳市',
    short_name: '沈阳',
    f_id: '210000',
  },
  {
    id: '210200',
    name: '大连市',
    short_name: '大连',
    f_id: '210000',
  },
  {
    id: '210300',
    name: '鞍山市',
    short_name: '鞍山',
    f_id: '210000',
  },
  {
    id: '210400',
    name: '抚顺市',
    short_name: '抚顺',
    f_id: '210000',
  },
  {
    id: '210500',
    name: '本溪市',
    short_name: '本溪',
    f_id: '210000',
  },
  {
    id: '210600',
    name: '丹东市',
    short_name: '丹东',
    f_id: '210000',
  },
  {
    id: '210700',
    name: '锦州市',
    short_name: '锦州',
    f_id: '210000',
  },
  {
    id: '210800',
    name: '营口市',
    short_name: '营口',
    f_id: '210000',
  },
  {
    id: '210900',
    name: '阜新市',
    short_name: '阜新',
    f_id: '210000',
  },
  {
    id: '211000',
    name: '辽阳市',
    short_name: '辽阳',
    f_id: '210000',
  },
  {
    id: '211100',
    name: '盘锦市',
    short_name: '盘锦',
    f_id: '210000',
  },
  {
    id: '211200',
    name: '铁岭市',
    short_name: '铁岭',
    f_id: '210000',
  },
  {
    id: '211300',
    name: '朝阳市',
    short_name: '朝阳',
    f_id: '210000',
  },
  {
    id: '211400',
    name: '葫芦岛市',
    short_name: '葫芦岛',
    f_id: '210000',
  },
  {
    id: '220100',
    name: '长春市',
    short_name: '长春',
    f_id: '220000',
  },
  {
    id: '220200',
    name: '吉林市',
    short_name: '吉林',
    f_id: '220000',
  },
  {
    id: '220300',
    name: '四平市',
    short_name: '四平',
    f_id: '220000',
  },
  {
    id: '220400',
    name: '辽源市',
    short_name: '辽源',
    f_id: '220000',
  },
  {
    id: '220500',
    name: '通化市',
    short_name: '通化',
    f_id: '220000',
  },
  {
    id: '220600',
    name: '白山市',
    short_name: '白山',
    f_id: '220000',
  },
  {
    id: '220700',
    name: '松原市',
    short_name: '松原',
    f_id: '220000',
  },
  {
    id: '220800',
    name: '白城市',
    short_name: '白城',
    f_id: '220000',
  },
  {
    id: '222400',
    name: '延边朝鲜族自治州',
    short_name: '延边朝鲜族',
    f_id: '220000',
  },
  {
    id: '230100',
    name: '哈尔滨市',
    short_name: '哈尔滨',
    f_id: '230000',
  },
  {
    id: '230200',
    name: '齐齐哈尔市',
    short_name: '齐齐哈尔',
    f_id: '230000',
  },
  {
    id: '230300',
    name: '鸡西市',
    short_name: '鸡西',
    f_id: '230000',
  },
  {
    id: '230400',
    name: '鹤岗市',
    short_name: '鹤岗',
    f_id: '230000',
  },
  {
    id: '230500',
    name: '双鸭山市',
    short_name: '双鸭山',
    f_id: '230000',
  },
  {
    id: '230600',
    name: '大庆市',
    short_name: '大庆',
    f_id: '230000',
  },
  {
    id: '230700',
    name: '伊春市',
    short_name: '伊春',
    f_id: '230000',
  },
  {
    id: '230800',
    name: '佳木斯市',
    short_name: '佳木斯',
    f_id: '230000',
  },
  {
    id: '230900',
    name: '七台河市',
    short_name: '七台河',
    f_id: '230000',
  },
  {
    id: '231000',
    name: '牡丹江市',
    short_name: '牡丹江',
    f_id: '230000',
  },
  {
    id: '231100',
    name: '黑河市',
    short_name: '黑河',
    f_id: '230000',
  },
  {
    id: '231200',
    name: '绥化市',
    short_name: '绥化',
    f_id: '230000',
  },
  {
    id: '232700',
    name: '大兴安岭地区',
    short_name: '大兴安岭',
    f_id: '230000',
  },
  {
    id: '310100',
    name: '上海市',
    short_name: '上海',
    f_id: '310000',
  },
  {
    id: '320100',
    name: '南京市',
    short_name: '南京',
    f_id: '320000',
  },
  {
    id: '320200',
    name: '无锡市',
    short_name: '无锡',
    f_id: '320000',
  },
  {
    id: '320300',
    name: '徐州市',
    short_name: '徐州',
    f_id: '320000',
  },
  {
    id: '320400',
    name: '常州市',
    short_name: '常州',
    f_id: '320000',
  },
  {
    id: '320500',
    name: '苏州市',
    short_name: '苏州',
    f_id: '320000',
  },
  {
    id: '320600',
    name: '南通市',
    short_name: '南通',
    f_id: '320000',
  },
  {
    id: '320700',
    name: '连云港市',
    short_name: '连云港',
    f_id: '320000',
  },
  {
    id: '320800',
    name: '淮安市',
    short_name: '淮安',
    f_id: '320000',
  },
  {
    id: '320900',
    name: '盐城市',
    short_name: '盐城',
    f_id: '320000',
  },
  {
    id: '321000',
    name: '扬州市',
    short_name: '扬州',
    f_id: '320000',
  },
  {
    id: '321100',
    name: '镇江市',
    short_name: '镇江',
    f_id: '320000',
  },
  {
    id: '321200',
    name: '泰州市',
    short_name: '泰州',
    f_id: '320000',
  },
  {
    id: '321300',
    name: '宿迁市',
    short_name: '宿迁',
    f_id: '320000',
  },
  {
    id: '330100',
    name: '杭州市',
    short_name: '杭州',
    f_id: '330000',
  },
  {
    id: '330200',
    name: '宁波市',
    short_name: '宁波',
    f_id: '330000',
  },
  {
    id: '330300',
    name: '温州市',
    short_name: '温州',
    f_id: '330000',
  },
  {
    id: '330400',
    name: '嘉兴市',
    short_name: '嘉兴',
    f_id: '330000',
  },
  {
    id: '330500',
    name: '湖州市',
    short_name: '湖州',
    f_id: '330000',
  },
  {
    id: '330600',
    name: '绍兴市',
    short_name: '绍兴',
    f_id: '330000',
  },
  {
    id: '330700',
    name: '金华市',
    short_name: '金华',
    f_id: '330000',
  },
  {
    id: '330800',
    name: '衢州市',
    short_name: '衢州',
    f_id: '330000',
  },
  {
    id: '330900',
    name: '舟山市',
    short_name: '舟山',
    f_id: '330000',
  },
  {
    id: '331000',
    name: '台州市',
    short_name: '台州',
    f_id: '330000',
  },
  {
    id: '331100',
    name: '丽水市',
    short_name: '丽水',
    f_id: '330000',
  },
  {
    id: '340100',
    name: '合肥市',
    short_name: '合肥',
    f_id: '340000',
  },
  {
    id: '340200',
    name: '芜湖市',
    short_name: '芜湖',
    f_id: '340000',
  },
  {
    id: '340300',
    name: '蚌埠市',
    short_name: '蚌埠',
    f_id: '340000',
  },
  {
    id: '340400',
    name: '淮南市',
    short_name: '淮南',
    f_id: '340000',
  },
  {
    id: '340500',
    name: '马鞍山市',
    short_name: '马鞍山',
    f_id: '340000',
  },
  {
    id: '340600',
    name: '淮北市',
    short_name: '淮北',
    f_id: '340000',
  },
  {
    id: '340700',
    name: '铜陵市',
    short_name: '铜陵',
    f_id: '340000',
  },
  {
    id: '340800',
    name: '安庆市',
    short_name: '安庆',
    f_id: '340000',
  },
  {
    id: '341000',
    name: '黄山市',
    short_name: '黄山',
    f_id: '340000',
  },
  {
    id: '341100',
    name: '滁州市',
    short_name: '滁州',
    f_id: '340000',
  },
  {
    id: '341200',
    name: '阜阳市',
    short_name: '阜阳',
    f_id: '340000',
  },
  {
    id: '341300',
    name: '宿州市',
    short_name: '宿州',
    f_id: '340000',
  },
  {
    id: '341500',
    name: '六安市',
    short_name: '六安',
    f_id: '340000',
  },
  {
    id: '341600',
    name: '亳州市',
    short_name: '亳州',
    f_id: '340000',
  },
  {
    id: '341700',
    name: '池州市',
    short_name: '池州',
    f_id: '340000',
  },
  {
    id: '341800',
    name: '宣城市',
    short_name: '宣城',
    f_id: '340000',
  },
  {
    id: '350100',
    name: '福州市',
    short_name: '福州',
    f_id: '350000',
  },
  {
    id: '350200',
    name: '厦门市',
    short_name: '厦门',
    f_id: '350000',
  },
  {
    id: '350300',
    name: '莆田市',
    short_name: '莆田',
    f_id: '350000',
  },
  {
    id: '350400',
    name: '三明市',
    short_name: '三明',
    f_id: '350000',
  },
  {
    id: '350500',
    name: '泉州市',
    short_name: '泉州',
    f_id: '350000',
  },
  {
    id: '350600',
    name: '漳州市',
    short_name: '漳州',
    f_id: '350000',
  },
  {
    id: '350700',
    name: '南平市',
    short_name: '南平',
    f_id: '350000',
  },
  {
    id: '350800',
    name: '龙岩市',
    short_name: '龙岩',
    f_id: '350000',
  },
  {
    id: '350900',
    name: '宁德市',
    short_name: '宁德',
    f_id: '350000',
  },
  {
    id: '360100',
    name: '南昌市',
    short_name: '南昌',
    f_id: '360000',
  },
  {
    id: '360200',
    name: '景德镇市',
    short_name: '景德镇',
    f_id: '360000',
  },
  {
    id: '360300',
    name: '萍乡市',
    short_name: '萍乡',
    f_id: '360000',
  },
  {
    id: '360400',
    name: '九江市',
    short_name: '九江',
    f_id: '360000',
  },
  {
    id: '360500',
    name: '新余市',
    short_name: '新余',
    f_id: '360000',
  },
  {
    id: '360600',
    name: '鹰潭市',
    short_name: '鹰潭',
    f_id: '360000',
  },
  {
    id: '360700',
    name: '赣州市',
    short_name: '赣州',
    f_id: '360000',
  },
  {
    id: '360800',
    name: '吉安市',
    short_name: '吉安',
    f_id: '360000',
  },
  {
    id: '360900',
    name: '宜春市',
    short_name: '宜春',
    f_id: '360000',
  },
  {
    id: '361000',
    name: '抚州市',
    short_name: '抚州',
    f_id: '360000',
  },
  {
    id: '361100',
    name: '上饶市',
    short_name: '上饶',
    f_id: '360000',
  },
  {
    id: '370100',
    name: '济南市',
    short_name: '济南',
    f_id: '370000',
  },
  {
    id: '370200',
    name: '青岛市',
    short_name: '青岛',
    f_id: '370000',
  },
  {
    id: '370300',
    name: '淄博市',
    short_name: '淄博',
    f_id: '370000',
  },
  {
    id: '370400',
    name: '枣庄市',
    short_name: '枣庄',
    f_id: '370000',
  },
  {
    id: '370500',
    name: '东营市',
    short_name: '东营',
    f_id: '370000',
  },
  {
    id: '370600',
    name: '烟台市',
    short_name: '烟台',
    f_id: '370000',
  },
  {
    id: '370700',
    name: '潍坊市',
    short_name: '潍坊',
    f_id: '370000',
  },
  {
    id: '370800',
    name: '济宁市',
    short_name: '济宁',
    f_id: '370000',
  },
  {
    id: '370900',
    name: '泰安市',
    short_name: '泰安',
    f_id: '370000',
  },
  {
    id: '371000',
    name: '威海市',
    short_name: '威海',
    f_id: '370000',
  },
  {
    id: '371100',
    name: '日照市',
    short_name: '日照',
    f_id: '370000',
  },
  {
    id: '371200',
    name: '莱芜市',
    short_name: '莱芜',
    f_id: '370000',
  },
  {
    id: '371300',
    name: '临沂市',
    short_name: '临沂',
    f_id: '370000',
  },
  {
    id: '371400',
    name: '德州市',
    short_name: '德州',
    f_id: '370000',
  },
  {
    id: '371500',
    name: '聊城市',
    short_name: '聊城',
    f_id: '370000',
  },
  {
    id: '371600',
    name: '滨州市',
    short_name: '滨州',
    f_id: '370000',
  },
  {
    id: '371700',
    name: '菏泽市',
    short_name: '菏泽',
    f_id: '370000',
  },
  {
    id: '410100',
    name: '郑州市',
    short_name: '郑州',
    f_id: '410000',
  },
  {
    id: '410200',
    name: '开封市',
    short_name: '开封',
    f_id: '410000',
  },
  {
    id: '410300',
    name: '洛阳市',
    short_name: '洛阳',
    f_id: '410000',
  },
  {
    id: '410400',
    name: '平顶山市',
    short_name: '平顶山',
    f_id: '410000',
  },
  {
    id: '410500',
    name: '安阳市',
    short_name: '安阳',
    f_id: '410000',
  },
  {
    id: '410600',
    name: '鹤壁市',
    short_name: '鹤壁',
    f_id: '410000',
  },
  {
    id: '410700',
    name: '新乡市',
    short_name: '新乡',
    f_id: '410000',
  },
  {
    id: '410800',
    name: '焦作市',
    short_name: '焦作',
    f_id: '410000',
  },
  {
    id: '410881',
    name: '济源市',
    short_name: '济源',
    f_id: '410000',
  },
  {
    id: '410900',
    name: '濮阳市',
    short_name: '濮阳',
    f_id: '410000',
  },
  {
    id: '411000',
    name: '许昌市',
    short_name: '许昌',
    f_id: '410000',
  },
  {
    id: '411100',
    name: '漯河市',
    short_name: '漯河',
    f_id: '410000',
  },
  {
    id: '411200',
    name: '三门峡市',
    short_name: '三门峡',
    f_id: '410000',
  },
  {
    id: '411300',
    name: '南阳市',
    short_name: '南阳',
    f_id: '410000',
  },
  {
    id: '411400',
    name: '商丘市',
    short_name: '商丘',
    f_id: '410000',
  },
  {
    id: '411500',
    name: '信阳市',
    short_name: '信阳',
    f_id: '410000',
  },
  {
    id: '411600',
    name: '周口市',
    short_name: '周口',
    f_id: '410000',
  },
  {
    id: '411700',
    name: '驻马店市',
    short_name: '驻马店',
    f_id: '410000',
  },
  {
    id: '420100',
    name: '武汉市',
    short_name: '武汉',
    f_id: '420000',
  },
  {
    id: '420200',
    name: '黄石市',
    short_name: '黄石',
    f_id: '420000',
  },
  {
    id: '420300',
    name: '十堰市',
    short_name: '十堰',
    f_id: '420000',
  },
  {
    id: '420500',
    name: '宜昌市',
    short_name: '宜昌',
    f_id: '420000',
  },
  {
    id: '420600',
    name: '襄阳市',
    short_name: '襄阳',
    f_id: '420000',
  },
  {
    id: '420700',
    name: '鄂州市',
    short_name: '鄂州',
    f_id: '420000',
  },
  {
    id: '420800',
    name: '荆门市',
    short_name: '荆门',
    f_id: '420000',
  },
  {
    id: '420900',
    name: '孝感市',
    short_name: '孝感',
    f_id: '420000',
  },
  {
    id: '421000',
    name: '荆州市',
    short_name: '荆州',
    f_id: '420000',
  },
  {
    id: '421100',
    name: '黄冈市',
    short_name: '黄冈',
    f_id: '420000',
  },
  {
    id: '421200',
    name: '咸宁市',
    short_name: '咸宁',
    f_id: '420000',
  },
  {
    id: '421300',
    name: '随州市',
    short_name: '随州',
    f_id: '420000',
  },
  {
    id: '422800',
    name: '恩施土家族苗族自治州',
    short_name: '恩施',
    f_id: '420000',
  },
  {
    id: '429004',
    name: '仙桃市',
    short_name: '仙桃',
    f_id: '420000',
  },
  {
    id: '429005',
    name: '潜江市',
    short_name: '潜江',
    f_id: '420000',
  },
  {
    id: '429006',
    name: '天门市',
    short_name: '天门',
    f_id: '420000',
  },
  {
    id: '429021',
    name: '神农架林区',
    short_name: '神农架',
    f_id: '420000',
  },
  {
    id: '430100',
    name: '长沙市',
    short_name: '长沙',
    f_id: '430000',
  },
  {
    id: '430200',
    name: '株洲市',
    short_name: '株洲',
    f_id: '430000',
  },
  {
    id: '430300',
    name: '湘潭市',
    short_name: '湘潭',
    f_id: '430000',
  },
  {
    id: '430400',
    name: '衡阳市',
    short_name: '衡阳',
    f_id: '430000',
  },
  {
    id: '430500',
    name: '邵阳市',
    short_name: '邵阳',
    f_id: '430000',
  },
  {
    id: '430600',
    name: '岳阳市',
    short_name: '岳阳',
    f_id: '430000',
  },
  {
    id: '430700',
    name: '常德市',
    short_name: '常德',
    f_id: '430000',
  },
  {
    id: '430800',
    name: '张家界市',
    short_name: '张家界',
    f_id: '430000',
  },
  {
    id: '430900',
    name: '益阳市',
    short_name: '益阳',
    f_id: '430000',
  },
  {
    id: '431000',
    name: '郴州市',
    short_name: '郴州',
    f_id: '430000',
  },
  {
    id: '431100',
    name: '永州市',
    short_name: '永州',
    f_id: '430000',
  },
  {
    id: '431200',
    name: '怀化市',
    short_name: '怀化',
    f_id: '430000',
  },
  {
    id: '431300',
    name: '娄底市',
    short_name: '娄底',
    f_id: '430000',
  },
  {
    id: '433100',
    name: '湘西土家族苗族自治州',
    short_name: '湘西',
    f_id: '430000',
  },
  {
    id: '440100',
    name: '广州市',
    short_name: '广州',
    f_id: '440000',
  },
  {
    id: '440200',
    name: '韶关市',
    short_name: '韶关',
    f_id: '440000',
  },
  {
    id: '440300',
    name: '深圳市',
    short_name: '深圳',
    f_id: '440000',
  },
  {
    id: '440400',
    name: '珠海市',
    short_name: '珠海',
    f_id: '440000',
  },
  {
    id: '440500',
    name: '汕头市',
    short_name: '汕头',
    f_id: '440000',
  },
  {
    id: '440600',
    name: '佛山市',
    short_name: '佛山',
    f_id: '440000',
  },
  {
    id: '440700',
    name: '江门市',
    short_name: '江门',
    f_id: '440000',
  },
  {
    id: '440800',
    name: '湛江市',
    short_name: '湛江',
    f_id: '440000',
  },
  {
    id: '440900',
    name: '茂名市',
    short_name: '茂名',
    f_id: '440000',
  },
  {
    id: '441200',
    name: '肇庆市',
    short_name: '肇庆',
    f_id: '440000',
  },
  {
    id: '441300',
    name: '惠州市',
    short_name: '惠州',
    f_id: '440000',
  },
  {
    id: '441400',
    name: '梅州市',
    short_name: '梅州',
    f_id: '440000',
  },
  {
    id: '441500',
    name: '汕尾市',
    short_name: '汕尾',
    f_id: '440000',
  },
  {
    id: '441600',
    name: '河源市',
    short_name: '河源',
    f_id: '440000',
  },
  {
    id: '441700',
    name: '阳江市',
    short_name: '阳江',
    f_id: '440000',
  },
  {
    id: '441800',
    name: '清远市',
    short_name: '清远',
    f_id: '440000',
  },
  {
    id: '441900',
    name: '东莞市',
    short_name: '东莞',
    f_id: '440000',
  },
  {
    id: '442000',
    name: '中山市',
    short_name: '中山',
    f_id: '440000',
  },
  {
    id: '442101',
    name: '东沙群岛',
    short_name: '东沙',
    f_id: '',
  },
  {
    id: '445100',
    name: '潮州市',
    short_name: '潮州',
    f_id: '440000',
  },
  {
    id: '445200',
    name: '揭阳市',
    short_name: '揭阳',
    f_id: '440000',
  },
  {
    id: '445300',
    name: '云浮市',
    short_name: '云浮',
    f_id: '440000',
  },
  {
    id: '450100',
    name: '南宁市',
    short_name: '南宁',
    f_id: '450000',
  },
  {
    id: '450200',
    name: '柳州市',
    short_name: '柳州',
    f_id: '450000',
  },
  {
    id: '450300',
    name: '桂林市',
    short_name: '桂林',
    f_id: '450000',
  },
  {
    id: '450400',
    name: '梧州市',
    short_name: '梧州',
    f_id: '450000',
  },
  {
    id: '450500',
    name: '北海市',
    short_name: '北海',
    f_id: '450000',
  },
  {
    id: '450600',
    name: '防城港市',
    short_name: '防城港',
    f_id: '450000',
  },
  {
    id: '450700',
    name: '钦州市',
    short_name: '钦州',
    f_id: '450000',
  },
  {
    id: '450800',
    name: '贵港市',
    short_name: '贵港',
    f_id: '450000',
  },
  {
    id: '450900',
    name: '玉林市',
    short_name: '玉林',
    f_id: '450000',
  },
  {
    id: '451000',
    name: '百色市',
    short_name: '百色',
    f_id: '450000',
  },
  {
    id: '451100',
    name: '贺州市',
    short_name: '贺州',
    f_id: '450000',
  },
  {
    id: '451200',
    name: '河池市',
    short_name: '河池',
    f_id: '450000',
  },
  {
    id: '451300',
    name: '来宾市',
    short_name: '来宾',
    f_id: '450000',
  },
  {
    id: '451400',
    name: '崇左市',
    short_name: '崇左',
    f_id: '450000',
  },
  {
    id: '460100',
    name: '海口市',
    short_name: '海口',
    f_id: '460000',
  },
  {
    id: '460200',
    name: '三亚市',
    short_name: '三亚',
    f_id: '460000',
  },
  {
    id: '460300',
    name: '三沙市',
    short_name: '三沙',
    f_id: '460000',
  },
  {
    id: '469001',
    name: '五指山市',
    short_name: '五指山',
    f_id: '460000',
  },
  {
    id: '469002',
    name: '琼海市',
    short_name: '琼海',
    f_id: '460000',
  },
  {
    id: '469003',
    name: '儋州市',
    short_name: '儋州',
    f_id: '460000',
  },
  {
    id: '469005',
    name: '文昌市',
    short_name: '文昌',
    f_id: '460000',
  },
  {
    id: '469006',
    name: '万宁市',
    short_name: '万宁',
    f_id: '460000',
  },
  {
    id: '469007',
    name: '东方市',
    short_name: '东方',
    f_id: '460000',
  },
  {
    id: '469025',
    name: '定安县',
    short_name: '定安',
    f_id: '460000',
  },
  {
    id: '469026',
    name: '屯昌县',
    short_name: '屯昌',
    f_id: '460000',
  },
  {
    id: '469027',
    name: '澄迈县',
    short_name: '澄迈',
    f_id: '460000',
  },
  {
    id: '469028',
    name: '临高县',
    short_name: '临高',
    f_id: '460000',
  },
  {
    id: '469030',
    name: '白沙黎族自治县',
    short_name: '白沙',
    f_id: '460000',
  },
  {
    id: '469031',
    name: '昌江黎族自治县',
    short_name: '昌江',
    f_id: '460000',
  },
  {
    id: '469033',
    name: '乐东黎族自治县',
    short_name: '乐东',
    f_id: '460000',
  },
  {
    id: '469034',
    name: '陵水黎族自治县',
    short_name: '陵水',
    f_id: '460000',
  },
  {
    id: '469035',
    name: '保亭黎族苗族自治县',
    short_name: '保亭',
    f_id: '460000',
  },
  {
    id: '469036',
    name: '琼中黎族苗族自治县',
    short_name: '琼中',
    f_id: '460000',
  },
  {
    id: '500100',
    name: '重庆市',
    short_name: '重庆',
    f_id: '500000',
  },
  {
    id: '510100',
    name: '成都市',
    short_name: '成都',
    f_id: '510000',
  },
  {
    id: '510300',
    name: '自贡市',
    short_name: '自贡',
    f_id: '510000',
  },
  {
    id: '510400',
    name: '攀枝花市',
    short_name: '攀枝花',
    f_id: '510000',
  },
  {
    id: '510500',
    name: '泸州市',
    short_name: '泸州',
    f_id: '510000',
  },
  {
    id: '510600',
    name: '德阳市',
    short_name: '德阳',
    f_id: '510000',
  },
  {
    id: '510700',
    name: '绵阳市',
    short_name: '绵阳',
    f_id: '510000',
  },
  {
    id: '510800',
    name: '广元市',
    short_name: '广元',
    f_id: '510000',
  },
  {
    id: '510900',
    name: '遂宁市',
    short_name: '遂宁',
    f_id: '510000',
  },
  {
    id: '511000',
    name: '内江市',
    short_name: '内江',
    f_id: '510000',
  },
  {
    id: '511100',
    name: '乐山市',
    short_name: '乐山',
    f_id: '510000',
  },
  {
    id: '511300',
    name: '南充市',
    short_name: '南充',
    f_id: '510000',
  },
  {
    id: '511400',
    name: '眉山市',
    short_name: '眉山',
    f_id: '510000',
  },
  {
    id: '511500',
    name: '宜宾市',
    short_name: '宜宾',
    f_id: '510000',
  },
  {
    id: '511600',
    name: '广安市',
    short_name: '广安',
    f_id: '510000',
  },
  {
    id: '511700',
    name: '达州市',
    short_name: '达州',
    f_id: '510000',
  },
  {
    id: '511800',
    name: '雅安市',
    short_name: '雅安',
    f_id: '510000',
  },
  {
    id: '511900',
    name: '巴中市',
    short_name: '巴中',
    f_id: '510000',
  },
  {
    id: '512000',
    name: '资阳市',
    short_name: '资阳',
    f_id: '510000',
  },
  {
    id: '513200',
    name: '阿坝藏族羌族自治州',
    short_name: '阿坝',
    f_id: '510000',
  },
  {
    id: '513300',
    name: '甘孜藏族自治州',
    short_name: '甘孜',
    f_id: '510000',
  },
  {
    id: '513400',
    name: '凉山彝族自治州',
    short_name: '凉山',
    f_id: '510000',
  },
  {
    id: '520100',
    name: '贵阳市',
    short_name: '贵阳',
    f_id: '520000',
  },
  {
    id: '520200',
    name: '六盘水市',
    short_name: '六盘水',
    f_id: '520000',
  },
  {
    id: '520300',
    name: '遵义市',
    short_name: '遵义',
    f_id: '520000',
  },
  {
    id: '520400',
    name: '安顺市',
    short_name: '安顺',
    f_id: '520000',
  },
  {
    id: '522200',
    name: '铜仁市',
    short_name: '铜仁',
    f_id: '520000',
  },
  {
    id: '522300',
    name: '黔西南布依族苗族自治州',
    short_name: '黔西南',
    f_id: '520000',
  },
  {
    id: '522400',
    name: '毕节市',
    short_name: '毕节',
    f_id: '520000',
  },
  {
    id: '522600',
    name: '黔东南苗族侗族自治州',
    short_name: '黔东南',
    f_id: '520000',
  },
  {
    id: '522700',
    name: '黔南布依族苗族自治州',
    short_name: '黔南',
    f_id: '520000',
  },
  {
    id: '530100',
    name: '昆明市',
    short_name: '昆明',
    f_id: '530000',
  },
  {
    id: '530300',
    name: '曲靖市',
    short_name: '曲靖',
    f_id: '530000',
  },
  {
    id: '530400',
    name: '玉溪市',
    short_name: '玉溪',
    f_id: '530000',
  },
  {
    id: '530500',
    name: '保山市',
    short_name: '保山',
    f_id: '530000',
  },
  {
    id: '530600',
    name: '昭通市',
    short_name: '昭通',
    f_id: '530000',
  },
  {
    id: '530700',
    name: '丽江市',
    short_name: '丽江',
    f_id: '530000',
  },
  {
    id: '530800',
    name: '普洱市',
    short_name: '普洱',
    f_id: '530000',
  },
  {
    id: '530900',
    name: '临沧市',
    short_name: '临沧',
    f_id: '530000',
  },
  {
    id: '532300',
    name: '楚雄彝族自治州',
    short_name: '楚雄',
    f_id: '530000',
  },
  {
    id: '532500',
    name: '红河哈尼族彝族自治州',
    short_name: '红河',
    f_id: '530000',
  },
  {
    id: '532600',
    name: '文山壮族苗族自治州',
    short_name: '文山',
    f_id: '530000',
  },
  {
    id: '532800',
    name: '西双版纳傣族自治州',
    short_name: '西双版纳',
    f_id: '530000',
  },
  {
    id: '532900',
    name: '大理白族自治州',
    short_name: '大理',
    f_id: '530000',
  },
  {
    id: '533100',
    name: '德宏傣族景颇族自治州',
    short_name: '德宏',
    f_id: '530000',
  },
  {
    id: '533300',
    name: '怒江傈僳族自治州',
    short_name: '怒江',
    f_id: '530000',
  },
  {
    id: '533400',
    name: '迪庆藏族自治州',
    short_name: '迪庆',
    f_id: '530000',
  },
  {
    id: '540100',
    name: '拉萨市',
    short_name: '拉萨',
    f_id: '540000',
  },
  {
    id: '542100',
    name: '昌都地区',
    short_name: '昌都',
    f_id: '540000',
  },
  {
    id: '542200',
    name: '山南地区',
    short_name: '山南',
    f_id: '540000',
  },
  {
    id: '542300',
    name: '日喀则地区',
    short_name: '日喀则',
    f_id: '540000',
  },
  {
    id: '542400',
    name: '那曲地区',
    short_name: '那曲',
    f_id: '540000',
  },
  {
    id: '542500',
    name: '阿里地区',
    short_name: '阿里',
    f_id: '540000',
  },
  {
    id: '542600',
    name: '林芝地区',
    short_name: '林芝',
    f_id: '540000',
  },
  {
    id: '610100',
    name: '西安市',
    short_name: '西安',
    f_id: '610000',
  },
  {
    id: '610200',
    name: '铜川市',
    short_name: '铜川',
    f_id: '610000',
  },
  {
    id: '610300',
    name: '宝鸡市',
    short_name: '宝鸡',
    f_id: '610000',
  },
  {
    id: '610400',
    name: '咸阳市',
    short_name: '咸阳',
    f_id: '610000',
  },
  {
    id: '610500',
    name: '渭南市',
    short_name: '渭南',
    f_id: '610000',
  },
  {
    id: '610600',
    name: '延安市',
    short_name: '延安',
    f_id: '610000',
  },
  {
    id: '610700',
    name: '汉中市',
    short_name: '汉中',
    f_id: '610000',
  },
  {
    id: '610800',
    name: '榆林市',
    short_name: '榆林',
    f_id: '610000',
  },
  {
    id: '610900',
    name: '安康市',
    short_name: '安康',
    f_id: '610000',
  },
  {
    id: '611000',
    name: '商洛市',
    short_name: '商洛',
    f_id: '610000',
  },
  {
    id: '620100',
    name: '兰州市',
    short_name: '兰州',
    f_id: '620000',
  },
  {
    id: '620200',
    name: '嘉峪关市',
    short_name: '嘉峪关',
    f_id: '620000',
  },
  {
    id: '620300',
    name: '金昌市',
    short_name: '金昌',
    f_id: '620000',
  },
  {
    id: '620400',
    name: '白银市',
    short_name: '白银',
    f_id: '620000',
  },
  {
    id: '620500',
    name: '天水市',
    short_name: '天水',
    f_id: '620000',
  },
  {
    id: '620600',
    name: '武威市',
    short_name: '武威',
    f_id: '620000',
  },
  {
    id: '620700',
    name: '张掖市',
    short_name: '张掖',
    f_id: '620000',
  },
  {
    id: '620800',
    name: '平凉市',
    short_name: '平凉',
    f_id: '620000',
  },
  {
    id: '620900',
    name: '酒泉市',
    short_name: '酒泉',
    f_id: '620000',
  },
  {
    id: '621000',
    name: '庆阳市',
    short_name: '庆阳',
    f_id: '620000',
  },
  {
    id: '621100',
    name: '定西市',
    short_name: '定西',
    f_id: '620000',
  },
  {
    id: '621200',
    name: '陇南市',
    short_name: '陇南',
    f_id: '620000',
  },
  {
    id: '622900',
    name: '临夏回族自治州',
    short_name: '临夏',
    f_id: '620000',
  },
  {
    id: '623000',
    name: '甘南藏族自治州',
    short_name: '甘南',
    f_id: '620000',
  },
  {
    id: '630100',
    name: '西宁市',
    short_name: '西宁',
    f_id: '630000',
  },
  {
    id: '632100',
    name: '海东市',
    short_name: '海东',
    f_id: '630000',
  },
  {
    id: '632200',
    name: '海北藏族自治州',
    short_name: '海北',
    f_id: '630000',
  },
  {
    id: '632300',
    name: '黄南藏族自治州',
    short_name: '黄南',
    f_id: '630000',
  },
  {
    id: '632500',
    name: '海南藏族自治州',
    short_name: '海南藏族',
    f_id: '630000',
  },
  {
    id: '632600',
    name: '果洛藏族自治州',
    short_name: '果洛',
    f_id: '630000',
  },
  {
    id: '632700',
    name: '玉树藏族自治州',
    short_name: '玉树',
    f_id: '630000',
  },
  {
    id: '632800',
    name: '海西蒙古族藏族自治州',
    short_name: '海西',
    f_id: '630000',
  },
  {
    id: '640100',
    name: '银川市',
    short_name: '银川',
    f_id: '640000',
  },
  {
    id: '640200',
    name: '石嘴山市',
    short_name: '石嘴山',
    f_id: '640000',
  },
  {
    id: '640300',
    name: '吴忠市',
    short_name: '吴忠',
    f_id: '640000',
  },
  {
    id: '640400',
    name: '固原市',
    short_name: '固原',
    f_id: '640000',
  },
  {
    id: '640500',
    name: '中卫市',
    short_name: '中卫',
    f_id: '640000',
  },
  {
    id: '650100',
    name: '乌鲁木齐市',
    short_name: '乌鲁木齐',
    f_id: '650000',
  },
  {
    id: '650200',
    name: '克拉玛依市',
    short_name: '克拉玛依',
    f_id: '650000',
  },
  {
    id: '652100',
    name: '吐鲁番地区',
    short_name: '吐鲁番',
    f_id: '650000',
  },
  {
    id: '652200',
    name: '哈密地区',
    short_name: '哈密',
    f_id: '650000',
  },
  {
    id: '652300',
    name: '昌吉回族自治州',
    short_name: '昌吉',
    f_id: '650000',
  },
  {
    id: '652700',
    name: '博尔塔拉蒙古自治州',
    short_name: '博尔塔拉',
    f_id: '650000',
  },
  {
    id: '652800',
    name: '巴音郭楞蒙古自治州',
    short_name: '巴音郭楞',
    f_id: '650000',
  },
  {
    id: '652900',
    name: '阿克苏地区',
    short_name: '阿克苏',
    f_id: '650000',
  },
  {
    id: '653000',
    name: '克孜勒苏柯尔克孜自治州',
    short_name: '克孜勒苏柯尔克孜',
    f_id: '650000',
  },
  {
    id: '653100',
    name: '喀什地区',
    short_name: '喀什',
    f_id: '650000',
  },
  {
    id: '653200',
    name: '和田地区',
    short_name: '和田',
    f_id: '650000',
  },
  {
    id: '654000',
    name: '伊犁哈萨克自治州',
    short_name: '伊犁',
    f_id: '650000',
  },
  {
    id: '654200',
    name: '塔城地区',
    short_name: '塔城',
    f_id: '650000',
  },
  {
    id: '654300',
    name: '阿勒泰地区',
    short_name: '阿勒泰',
    f_id: '650000',
  },
  {
    id: '659001',
    name: '石河子市',
    short_name: '石河子',
    f_id: '650000',
  },
  {
    id: '659002',
    name: '阿拉尔市',
    short_name: '阿拉尔',
    f_id: '650000',
  },
  {
    id: '659003',
    name: '图木舒克市',
    short_name: '图木舒克',
    f_id: '650000',
  },
  {
    id: '659004',
    name: '五家渠市',
    short_name: '五家渠',
    f_id: '650000',
  },
  {
    id: '710100',
    name: '台北市',
    short_name: '台北',
    f_id: '710000',
  },
  {
    id: '710200',
    name: '高雄市',
    short_name: '高雄',
    f_id: '710000',
  },
  {
    id: '710300',
    name: '台南市',
    short_name: '台南',
    f_id: '710000',
  },
  {
    id: '710400',
    name: '台中市',
    short_name: '台中',
    f_id: '710000',
  },
  {
    id: '710500',
    name: '金门县',
    short_name: '金门',
    f_id: '710000',
  },
  {
    id: '710600',
    name: '南投县',
    short_name: '南投',
    f_id: '710000',
  },
  {
    id: '710700',
    name: '基隆市',
    short_name: '基隆',
    f_id: '710000',
  },
  {
    id: '710800',
    name: '新竹市',
    short_name: '新竹',
    f_id: '710000',
  },
  {
    id: '710900',
    name: '嘉义市',
    short_name: '嘉义',
    f_id: '710000',
  },
  {
    id: '711100',
    name: '新北市',
    short_name: '新北',
    f_id: '710000',
  },
  {
    id: '711200',
    name: '宜兰县',
    short_name: '宜兰',
    f_id: '710000',
  },
  {
    id: '711300',
    name: '新竹县',
    short_name: '新竹',
    f_id: '710000',
  },
  {
    id: '711400',
    name: '桃园县',
    short_name: '桃园',
    f_id: '710000',
  },
  {
    id: '711500',
    name: '苗栗县',
    short_name: '苗栗',
    f_id: '710000',
  },
  {
    id: '711700',
    name: '彰化县',
    short_name: '彰化',
    f_id: '710000',
  },
  {
    id: '711900',
    name: '嘉义县',
    short_name: '嘉义',
    f_id: '710000',
  },
  {
    id: '712100',
    name: '云林县',
    short_name: '云林',
    f_id: '710000',
  },
  {
    id: '712400',
    name: '屏东县',
    short_name: '屏东',
    f_id: '710000',
  },
  {
    id: '712500',
    name: '台东县',
    short_name: '台东',
    f_id: '710000',
  },
  {
    id: '712600',
    name: '花莲县',
    short_name: '花莲',
    f_id: '710000',
  },
  {
    id: '712700',
    name: '澎湖县',
    short_name: '澎湖',
    f_id: '710000',
  },
  {
    id: '712800',
    name: '连江县',
    short_name: '连江',
    f_id: '710000',
  },
  {
    id: '810100',
    name: '香港岛',
    short_name: '香港岛',
    f_id: '810000',
  },
  {
    id: '810200',
    name: '九龙',
    short_name: '九龙',
    f_id: '810000',
  },
  {
    id: '810300',
    name: '新界',
    short_name: '新界',
    f_id: '810000',
  },
  {
    id: '820100',
    name: '澳门半岛',
    short_name: '澳门半岛',
    f_id: '820000',
  },
  {
    id: '820200',
    name: '离岛',
    short_name: '离岛',
    f_id: '820000',
  },
]

export default cityData
