export const BASE_URL = import.meta.env.VITE_BASE_HOST
export const UPLOAD_URL = `${BASE_URL}api/file/qcts/upload`

// 加载状态
export enum LOAD_STATUS {
  INIT = 'init',
  LOADING = 'loading',
  LOAD_MORE = 'loadMore',
  ERROR = 'error',
  END = 'end',
  NULL = 'null',
}
// 用户类型
export enum USER_TYPE {
  SELF = '0',
  INDIVIDUAL = '1', // 个人网评号
  INSTITUTIONAL = '2', // 机构网评号
  DEALER = '3', // 经销商
}
export const USER_TYPE_TEXT: Record<USER_TYPE, string> = {
  [USER_TYPE.SELF]: '自营',
  [USER_TYPE.INDIVIDUAL]: '个人',
  [USER_TYPE.INSTITUTIONAL]: '机构',
  [USER_TYPE.DEALER]: '经销商',
}

/**
 * 字典码-网评号用户状态
 */
export enum USER_STATUS {
  NORMAL = '0', // 正常
  STOP = '1', // 停用
}
export const USER_STATUS_TEXT: Record<USER_STATUS, string> = {
  [USER_STATUS.NORMAL]: '正常',
  [USER_STATUS.STOP]: '停用',
}

/**
 * 字典码-网评号主体认证状态 media_user_subject_auth_status
 */
export enum USER_SUBJECT_TYPE {
  NOT = '0', // 未认证
  CERTIFIED = '1', // 已认证 certified
  EXEMPTION = '2', // 免认证
}
export const USER_SUBJECT_TYPE_TEXT: Record<USER_SUBJECT_TYPE, string> = {
  [USER_SUBJECT_TYPE.NOT]: '未认证',
  [USER_SUBJECT_TYPE.CERTIFIED]: '已认证',
  [USER_SUBJECT_TYPE.EXEMPTION]: '免认证',
}

/**
 * 字典码-网评号信息审核状态
 */
export enum USER_SUBJECT_AUDIT_TYPE {
  REFUSE = '-1',
  UNTRIED = '0',
  RETRIAL = '00',
  AGREE = '1',
}
export const USER_SUBJECT_AUDIT_TYPE_TEXT: Record<USER_SUBJECT_AUDIT_TYPE, string> = {
  [USER_SUBJECT_AUDIT_TYPE.REFUSE]: '拒绝',
  [USER_SUBJECT_AUDIT_TYPE.UNTRIED]: '待审核', // 未审
  [USER_SUBJECT_AUDIT_TYPE.RETRIAL]: '重审',
  [USER_SUBJECT_AUDIT_TYPE.AGREE]: '通过',
}

// 用户性别
export enum USER_SEX {
  NULL = '0',
  MALE = '1',
  FEMALE = '2',
}
export const USER_SEX_TEXT: Record<USER_SEX, string> = {
  [USER_SEX.NULL]: '未知',
  [USER_SEX.MALE]: '男',
  [USER_SEX.FEMALE]: '女',
}

// 第三方平台
export enum THIRD_PLATFORM {
  WECHAT = 'WECHAT',
}
export const THIRD_PLATFORM_TEXT: Record<THIRD_PLATFORM, string> = {
  [THIRD_PLATFORM.WECHAT]: '微信',
}

// 询价和试驾信息处理状态
export enum CONSULT_STATUS {
  DEFAULT = '0',
  HANDLE = '1',
  DELETED = '2',
}
export const CONSULT_STATUS_TEXT: Record<CONSULT_STATUS, string> = {
  [CONSULT_STATUS.DEFAULT]: '未处理',
  [CONSULT_STATUS.HANDLE]: '已处理',
  [CONSULT_STATUS.DELETED]: '已删除',
}

/**
 * 字典码-文章性质-article_nature
 */
export enum ARTICLE_NATURE {
  NONE = '',
  ACTIVE = '1',
  COOPERATION = '2',
}
export const ARTICLE_NATURE_TEXT: Record<ARTICLE_NATURE, string> = {
  [ARTICLE_NATURE.NONE]: '',
  [ARTICLE_NATURE.ACTIVE]: '活动稿',
  [ARTICLE_NATURE.COOPERATION]: '活动稿',
}

/**
 * 字典码-文章状态-article_status
 */
export enum ARTICLE_STATUS {
  REJECT = '-1',
  UNTRIED = '0',
  REVIEWED = '1',
  DRAFT = '2',
  DELETE = '3',
  HIDE = '4',
}
export const ARTICLE_STATUS_TEXT: Record<ARTICLE_STATUS, string> = {
  [ARTICLE_STATUS.REJECT]: '不通过',
  [ARTICLE_STATUS.UNTRIED]: '未审',
  [ARTICLE_STATUS.REVIEWED]: '已审',
  [ARTICLE_STATUS.DRAFT]: '草稿箱',
  [ARTICLE_STATUS.DELETE]: '网评号删除',
  [ARTICLE_STATUS.HIDE]: '隐藏',
}

/**
 * 字典码-文章类型-article_type
 */
export enum ARTICLE_TYPE {
  PICTURE = '1', // 图文
  VIDEO = '2',
  LINK = '3',
  LINK_VIDEO = '4',
  LIVE = '5', // 直播
  // VIDEO_RO_LINK_VIDEO = '2,4',
}

export const ARTICLE_TYPE_TEXT: Record<ARTICLE_TYPE, string> = {
  [ARTICLE_TYPE.PICTURE]: '图文',
  [ARTICLE_TYPE.VIDEO]: '视频',
  [ARTICLE_TYPE.LINK]: '外链',
  [ARTICLE_TYPE.LINK_VIDEO]: '视频外链',
  [ARTICLE_TYPE.LIVE]: '直播',
}

/**
 * 榜单类型
 */
export enum TOP_TYPE {
  WEEK = '1',
  MONTH = '2',
}
/**
 * swiper 类型
 */
export enum BANNER_DATA_TYPE {
  LINK = '0',
  ARTICLE = '1',
  TOPIC = '2',
  LIVE = '3',
}

export const BANNER_DATA_TYPE_TEXT: Record<BANNER_DATA_TYPE, string> = {
  [BANNER_DATA_TYPE.LINK]: '外链',
  [BANNER_DATA_TYPE.ARTICLE]: '文章',
  [BANNER_DATA_TYPE.TOPIC]: '专题',
  [BANNER_DATA_TYPE.LIVE]: '直播',
}
export enum TOPIC_COMPONENT_DATA_TYPE { // 1=文章,2=专题,3=焦图
  ARTICLE = '1',
  TOPIC = '2',
  BANNER = '3',
}

export enum TOPIC_COMPONENT_TYPE {
  STATIC_1 = 'static_1', // 横幅图组件
  AD_IMAGE = 'recomm_image', // 广告图组件
  CAR_BRAND_1 = 'car_brand_vendor_1', // 汽车品牌组件
  TOPIC_1 = 'topic_1', // 专题推荐组件
  BANNER_1 = 'banner_1', // 轮播图组件
  ARTICLE_1 = 'article_1', // 文字链组件
  ARTICLE_3 = 'article_3', // 新车资讯组件
  ARTICLE_4 = 'article_4', // 热门内容组件
  ARTICLE_5 = 'article_5', // 热门内容组件
  RED_BLACK = 'red_black', // 吐槽与诚信榜组件, 红黑榜组件
}
export enum TOPIC_COMPONENT_TYPE_CAR_BRAND {
  CAR_BRAND_1 = TOPIC_COMPONENT_TYPE.CAR_BRAND_1,
}
export enum TOPIC_COMPONENT_TYPE_OTHER {
  STATIC_1 = TOPIC_COMPONENT_TYPE.STATIC_1,
}
export enum TOPIC_COMPONENT_TYPE_BANNER {
  BANNER_1 = TOPIC_COMPONENT_TYPE.BANNER_1,
}
export enum TOPIC_COMPONENT_TYPE_TOPIC {
  TOPIC_1 = TOPIC_COMPONENT_TYPE.TOPIC_1,
}
export enum TOPIC_COMPONENT_TYPE_ARTICLE {
  ARTICLE_1 = TOPIC_COMPONENT_TYPE.ARTICLE_1,
  ARTICLE_3 = TOPIC_COMPONENT_TYPE.ARTICLE_3,
  ARTICLE_4 = TOPIC_COMPONENT_TYPE.ARTICLE_4,
  ARTICLE_5 = TOPIC_COMPONENT_TYPE.ARTICLE_5,
  RED_BLACK = TOPIC_COMPONENT_TYPE.RED_BLACK,
}

export enum AUDIT_TYPE {
  REFUSE = '-1',
  UNTRIED = '0',
  AGREE = '1',
}
export const AUDIT_TYPE_TEXT: Record<AUDIT_TYPE, string> = {
  [AUDIT_TYPE.REFUSE]: '拒绝',
  [AUDIT_TYPE.UNTRIED]: '待审核', // 未审
  [AUDIT_TYPE.AGREE]: '通过',
}

// article_paas_source
export enum PAAS_SOURCE {
  // BAIDU = '1',
  TOUTIAO = '2',
  // DONGCHEDI = '60',
}
export const PAAS_SOURCE_TEXT: Record<PAAS_SOURCE, string> = {
  // [PAAS_SOURCE.BAIDU]: '百家号',
  [PAAS_SOURCE.TOUTIAO]: '头条号',
  // [PAAS_SOURCE.DONGCHEDI]: '懂车号',
}
export const PAAS_SOURCE_TEST: Record<PAAS_SOURCE, string> = {
  // [PAAS_SOURCE.BAIDU]: '百家号',
  [PAAS_SOURCE.TOUTIAO]: 'https://www.toutiao.com/c/user/token/MS4wLjABAAAApV38R6KOc0cqG0GNgIyWhmh6mwSqwh1jP5kCbAx5w-8/#mid=1556874813694978',
  // [PAAS_SOURCE.DONGCHEDI]: 'https://www.dongchedi.com/user/3335465294',
}
export enum NOTICE_TYPE {
  'NOTICE' = '1',
  'ANNOUNCEMENT' = '2',
}
export const NOTICE_TYPE_TEXT: Record<NOTICE_TYPE, string> = {
  [NOTICE_TYPE.NOTICE]: '通知',
  [NOTICE_TYPE.ANNOUNCEMENT]: '公告',
}
export enum NOTICE_STATUS {
  'NORMAL' = '0',
  'CLOSE' = '1',
}
export const NOTICE_STATUS_TEXT: Record<NOTICE_STATUS, string> = {
  [NOTICE_STATUS.NORMAL]: '正常',
  [NOTICE_STATUS.CLOSE]: '关闭',
}

export enum USER_PAAS_TYPE {
  'PLATFORM' = '1',
  'MEDIA' = '2',
}
export const USER_PAAS_TYPE_TEXT: Record<USER_PAAS_TYPE, string> = {
  [USER_PAAS_TYPE.PLATFORM]: '平台端',
  [USER_PAAS_TYPE.MEDIA]: '媒体端',
}
